import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const CurrencyIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.1666C15.0627 19.1666 19.1667 15.0626 19.1667 9.99998C19.1667 4.93737 15.0627 0.833313 10 0.833313C4.93743 0.833313 0.833374 4.93737 0.833374 9.99998C0.833374 15.0626 4.93743 19.1666 10 19.1666ZM10.8215 6.51065C13.1766 6.87728 15 8.78926 15 11.1202V12.097C15 12.3237 14.8824 12.5199 14.742 12.6512C14.6003 12.7838 14.3996 12.8845 14.1786 12.8845C13.9576 12.8845 13.7569 12.7838 13.6152 12.6512C13.4748 12.5199 13.3571 12.3237 13.3571 12.097V11.1202C13.3571 9.68718 12.2951 8.45731 10.8215 8.10981V12.097C10.8215 12.3237 10.7039 12.5199 10.5635 12.6512C10.4217 12.7838 10.2211 12.8845 10.0001 12.8845C9.52094 12.8845 9.1786 12.5058 9.1786 12.097V8.10981C7.70501 8.45731 6.643 9.68718 6.643 11.1202V12.097C6.643 12.5058 6.30067 12.8845 5.82154 12.8845C5.34242 12.8845 5.00008 12.5058 5.00008 12.097V11.1202C5.00008 8.78926 6.82353 6.87728 9.1786 6.51065V6.23617C9.1786 5.74097 9.61324 5.44863 10.0001 5.44863C10.3869 5.44863 10.8215 5.74097 10.8215 6.23617V6.51065ZM10.5224 12.097C10.5224 12.1266 10.5185 12.1562 10.5113 12.1853C10.5185 12.1561 10.5224 12.1265 10.5224 12.097V7.7503L10.5224 7.75029V12.097ZM6.38162 10.6299C6.35673 10.79 6.34386 10.9537 6.34386 11.1202V12.097C6.34386 12.1213 6.34178 12.1456 6.33773 12.1697C6.34177 12.1456 6.34383 12.1213 6.34383 12.097V11.1202C6.34383 10.9538 6.35673 10.79 6.38162 10.6299ZM5.42655 10.1065C5.8795 8.33606 7.49226 6.97971 9.47773 6.77342V6.7735C7.49228 6.97978 5.87953 8.3361 5.42655 10.1065ZM10.0001 5.74778C9.7746 5.74778 9.54913 5.89339 9.49171 6.12175C9.54916 5.89343 9.7746 5.74786 10 5.74786C10.2612 5.74786 10.5224 5.94322 10.5224 6.23626V6.7735C12.8728 7.0177 14.7009 8.87361 14.7009 11.1202V12.097C14.7009 12.1175 14.699 12.138 14.6955 12.1583C14.6991 12.138 14.7009 12.1175 14.7009 12.097V11.1202C14.7009 8.87353 12.8728 7.01761 10.5224 6.77341V6.23617C10.5224 5.94314 10.2612 5.74778 10.0001 5.74778Z"
      />
    </svg>
  );
};

export default CurrencyIcon;
