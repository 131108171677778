import { LoginRequest } from "@/services/auth";
import { getAccessToken, getUserInfo, removeAccessToken } from "@/utils/auth";
import { createContext, PropsWithChildren, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export type UserAuthority = "ADMIN" | "SUPER_ADMIN" | "SPONSOR";

export type User = {
  authority: UserAuthority[];
  exp: 1692230809;
  iat: 1692229009;
  id: string;
  name: string;
  status: string;
  sub: string;
  surname: string;
  type: string;
  companyName: string;
};

// const ACCESS_TOKEN_EXPIRES_TIME = 1000 * 60 * 15; // 15 min

export const AuthContext = createContext<{
  token: string;
  user: User | null;
  loading: boolean;
  login: (req: LoginRequest) => void;
  logout: () => void;
}>({
  token: "",
  user: null,
  loading: false,
  login: () => {},
  logout: () => {},
});

type AuthProviderProps = PropsWithChildren & {};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const localAccessToken = getAccessToken();
  const user: User = getUserInfo();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    removeAccessToken();
    navigate("/login");
  }, [navigate]);

  const login = async (req: LoginRequest) => {
    setLoading(true);

    // loginService(req)
    //   .then((data) => {
    //     if (data) {
    //       setTokens(data);

    //       navigate("/");
    //     }
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        token: localAccessToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
