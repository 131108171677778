import { LoginResponse } from "@/services/auth";
import jwt_decode from "jwt-decode";
import { User } from "@/providers/AuthProvider";

export const setTokens = ({ accessToken, refreshToken }: LoginResponse) => {
  const user = jwt_decode(accessToken);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("access-token", accessToken);
  localStorage.setItem("refresh-token", refreshToken);
};

export const getRefreshToken = () => {
  const token = localStorage.getItem("refresh-token") || "";

  return token;
};

export const getAccessToken = () => {
  const token = localStorage.getItem("access-token") || "";

  return token;
};

export const removeAccessToken = () => {
  localStorage.removeItem("access-token");
};

export const getUserInfo = () => {
  const user = localStorage.getItem("user") || "";

  if (user === null || user === "") {
    return null;
  }

  return JSON.parse(user);
};

export const deleteTokens = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");
};

export const isTokenExpired = (token: string) => {
  try {
    const decoded: User = jwt_decode(token);

    if (!decoded.exp) {
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime > decoded.exp;
  } catch (error) {
    return true;
  }
};

export const isSuperAdmin = (user: User | null) => {
  return user?.authority.some((a) => a === "SUPER_ADMIN");
};

export const isAdmin = (user: User | null) => {
  return user?.authority.some((a) => a === "ADMIN");
};

export const isSponsor = (user: User | null) => {
  return user?.authority.some((a) => a === "SPONSOR");
};
