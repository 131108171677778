import { ReactElement } from "react";
import CloseIcon from "./Close";
import EyeIcon from "./Eye";
import PlusIcon from "./Plus";
import HomeIcon from "./Home";
import SettingsIcon from "./Settings";
import UsersIcon from "./Users";
import SearchIcon from "./Search";
import MoonIcon from "./Moon";
import BellIcon from "./Bell";
import ZapIcon from "./Zap";
import ChevronLeft from "./ChevronLeft";
import PlusCricleIcon from "./PlusCircle";
import CheckIcon from "./Check";
import UploadIcon from "./Upload";
import FileIcon from "./File";
import FilterIcon from "./Filter";
import AzeIcon from "./Aze";
import EngIcon from "./Eng";
import RusIcon from "./Rus";
import ThreeDots from "./ThreeDots";
import PenIcon from "./Pen";
import ChevronDoubleLeftIcon from "./ChevronDoubleLeft";
import ChevronDoubleRightIcon from "./ChevronDoubleRight";
import ChevronRight from "./ChevronRight";
import LogoutIcon from "./Logout";
import CurrencyIcon from "./Currency";
import ChevronDown from "./ChevronDown";
import SpinnerIcon from "./Spinner";
import { InfoCircle } from "./InfoCircle";

type Props = {
  className?: string;
  name: IconName;
};

const iconMapper = {
  aze: AzeIcon,
  bell: BellIcon,
  check: CheckIcon,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-double-left": ChevronDoubleLeftIcon,
  "chevron-double-right": ChevronDoubleRightIcon,
  "chevron-down": ChevronDown,
  currency: CurrencyIcon,
  close: CloseIcon,
  eng: EngIcon,
  eye: EyeIcon,
  "eye-closed": EyeIcon,
  file: FileIcon,
  filter: FilterIcon,
  home: HomeIcon,
  infoCircle: InfoCircle,
  logout: LogoutIcon,
  moon: MoonIcon,
  pen: PenIcon,
  plus: PlusIcon,
  "plus-circle": PlusCricleIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  spinner: SpinnerIcon,
  rus: RusIcon,
  "three-dots": ThreeDots,
  upload: UploadIcon,
  users: UsersIcon,
  zap: ZapIcon,
};

export type IconName = keyof typeof iconMapper;

const Icon = (props: Props) => {
  const { name, className = "" } = props;
  let Component: ((props: Props) => ReactElement) | null = null;
  let additionalProps = {};

  if (!iconMapper[name]) throw new Error(`Icon ${props.name} not found`);

  Component = iconMapper[name];

  return <Component {...props} {...additionalProps} className={className} />;
};

export default Icon;
