import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

const PenIcon = (props: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="#62717C"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.8536 1.14645C10.106 0.398899 8.89401 0.398898 8.14646 1.14644L7.5657 1.72721C7.36769 1.92522 7.26869 2.02422 7.23159 2.13838C7.19896 2.23881 7.19896 2.34698 7.23159 2.4474C7.26869 2.56156 7.36769 2.66057 7.5657 2.85858L9.14143 4.43431C9.33944 4.63232 9.43845 4.73133 9.55261 4.76842C9.65303 4.80105 9.76121 4.80105 9.86163 4.76842C9.97579 4.73133 10.0748 4.63232 10.2728 4.43431L10.8536 3.85355C11.6011 3.10601 11.6011 1.89399 10.8536 1.14645Z" />
      <path d="M8.43433 6.27279C8.63234 6.07478 8.73134 5.97578 8.76843 5.86161C8.80106 5.76119 8.80106 5.65302 8.76843 5.5526C8.73134 5.43843 8.63234 5.33943 8.43433 5.14142L6.85859 3.56568C6.66058 3.36767 6.56158 3.26867 6.44741 3.23158C6.34699 3.19895 6.23882 3.19895 6.1384 3.23158C6.02423 3.26867 5.92523 3.36767 5.72722 3.56568L1.51837 7.77451C1.39572 7.89695 1.28754 8.00497 1.20291 8.1333C1.1285 8.24613 1.06969 8.3685 1.02807 8.49708C0.98072 8.64333 0.963959 8.79529 0.944958 8.96755L0.753061 10.6948C0.736288 10.8457 0.789046 10.9961 0.89645 11.1036C1.00385 11.211 1.15426 11.2637 1.30522 11.2469L3.03247 11.055C3.20473 11.036 3.35667 11.0193 3.50292 10.9719C3.6315 10.9303 3.75387 10.8715 3.8667 10.7971C3.99504 10.7125 4.10305 10.6043 4.2255 10.4816L8.43433 6.27279Z" />
    </svg>
  );
};

export default PenIcon;
