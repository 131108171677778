import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import AuthProvider from "./AuthProvider";
import ToastProvider from "./ToastProvider";

export const queryClient = new QueryClient();

const LayoutProviders = () => {
  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      </QueryClientProvider>
    </ToastProvider>
  );
};

export default LayoutProviders;
