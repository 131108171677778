import axiosInstance from "@/http";
import axios, { AxiosResponse } from "axios";

export type LoginRequest = {
  username: string;
  password: string;
};

export type LoginResponse = {
  refreshToken: string;
  accessToken: string;
};

export type RegisterRequest = {
  password: string;
  companyName: string;
  position: string;
  name: string;
  surname: string;
  token: string;
};

export type ForgotPasswordRequest = {
  token: string;
  password: string;
  repeatPassword: string;
};

export const updateAccessToken = async (refreshToken: string) => {
  return axiosInstance.post<null, AxiosResponse<LoginResponse>>(
    "api/customer-management-ms/v1/management/token/refresh",
    {},
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );
};

export const loginService = async (req: LoginRequest) => {
  try {
    const resp = await axiosInstance.post<
      LoginRequest,
      AxiosResponse<LoginResponse>
    >("api/customer-management-ms/v1/management/login", req);

    return resp.data;
  } catch (e) {
    if (axios.isAxiosError<Error>(e)) {
      throw e;
    }

    throw new Error("Something went wrong");
  }
};

export const emailVerificationService = (email: string) => {
  return new Promise<LoginResponse>(async (resolve, reject) => {
    try {
      const resp = await axiosInstance.post<
        string,
        AxiosResponse<LoginResponse>
      >(`api/customer-management-ms/v1/management/email/verify?email=${email}`);

      return resolve(resp.data);
    } catch (e) {
      if (axios.isAxiosError<Error>(e)) {
        return reject(e.response?.data.detail);
      }
      return reject(e);
    }
  });
};

export const registerService = async (req: RegisterRequest) => {
  try {
    const resp = await axiosInstance.post<
      RegisterRequest,
      AxiosResponse<LoginResponse>
    >("api/customer-management-ms/v1/management/register", req);

    return resp.data;
  } catch (e) {
    console.log(e);
  }
};

export const forgotPasswordService = async (username: string) => {
  try {
    const resp = await axiosInstance.post(
      `api/customer-management-ms/v1/management/password/reset`,
      {
        username,
      }
    );

    return resp.data;
  } catch (e) {
    throw new Error("Something went wrong");
  }
};

export const forgotPasswordUpdateService = async (
  body: ForgotPasswordRequest
) => {
  try {
    const resp = await axiosInstance.put(
      `api/customer-management-ms/v1/management/password/update`,
      body
    );

    return resp.data;
  } catch (e) {
    console.log(e);
  }
};
