import Button, { ButtonProps } from "./";
import Icon, { IconName } from "@/components/Icons";

type IconButtonProps = ButtonProps & {
  icon: IconName;
  iconClassName?: string;
};

const IconButton = (props: IconButtonProps) => {
  const {
    size = "sm",
    icon,
    mode = "default",
    iconClassName = "",
    ...rest
  } = props;

  return (
    <Button circle size={size} mode={mode} {...rest}>
      <Icon name={icon} className={iconClassName} />
    </Button>
  );
};

export default IconButton;
